<template>
    <div class="selectPersonnel" style="width:100%;">
        <div class="selectGather" v-if="contacts=='recent'" ref="selectBox" @click="selectListBool=true">
            <div class="selectItem" v-for="(item,index) in returnList" :key="index">
                {{item.title}}
                <a-icon
                        type="close"
                        @click.stop="delChangeFn(item.id)"
                        style="font-size:12px;margin: 0px 6px;cursor:pointer;"
                />
            </div>

            <div
                    class="contacts"
                    v-show="selectListBool"
                    :style="{top:boxHeight}"
                    @mouseenter="cx()"
                    @mouseleave="selectListBool=false"
            >
                <ul class="contacts_list">
                    <label v-for="(item,i) in contactPersonList" :key="i">
                        <li :class="[returnList.indexOf(item)>-1?'contact_item fontStyle':'contact_item']">
                            <!-- @click="contactChangeFn(item)" -->
                            {{item.title}}
                            <a-icon
                                    type="check"
                                    class="ant-menu-item-selected"
                                    v-show="returnList.indexOf(item)>-1"
                                    style="float:right;margin: 8px 0px 0px 0px;"
                            />
                            <input type="checkbox" value="cx111" @change="contactChangeFn(item)"/>
                        </li>
                    </label>
                </ul>
                <div
                        @click="_showModal()"
                        class="ant-menu-item-selected"
                        style="text-align:center;cursor:pointer;padding-top:4px;"
                >打开组织架构
                </div>
            </div>
        </div>

        <div class="selectGather" ref="selectBox" @click.stop="_showModal()" v-else>
            <div class="selectItem" v-for="(item,index) in returnList" :key="index">
                {{item.title}}
                <a-icon
                        type="close"
                        @click.stop="delChangeFn(item.id)"
                        style="font-size:12px;margin: 0px 6px;cursor:pointer;"
                />
            </div>
            <p v-if="returnList.length==0" style="line-height: 30px;color: #aaa;text-align: left;padding-left: 5px">
                {{placeholder}}</p>
        </div>
        <!-- 弹出层 -->
        <a-modal
                v-model="visible"
                width="980px"
                :bodyStyle="{'padding':'0px 0px'}"
                :footer="null"
                :closable="false"

        >
            <div style="display: flex;" v-if="visible">
                <!-- 选项区域 -->
                <div class="personnel-list">
                    <!-- 搜索框 -->
                    <div class="ant-modal-title ml-2">选择器</div>
                    <a-divider/>
                    <a-input-search
                            :placeholder="department==2?'请输入部门名称':department==4?'请输入中心名称':'请输入姓名'"
                            @search="onSearch"
                            style="width: 620px;"
                            size="large"
                            enterButton

                    />
<!--                  v-if="department<=2&&tabIndex==0"-->
                    <a-divider v-if="department<2&&tabIndex==0"/>
                    <!-- 选项切换 -->
                    <div style="display:flex;">
                        <div>
                            <a-button-group style="margin: 16px 20px;">
                                <a-button v-show="department!=3" @click="tabFn(0)"
                                          :class="tabIndex==0?'ant-btn-primary':''">{{department>3?'中心':'部门'}}
                                </a-button>
                                <a-button v-show="rank!=0" @click="tabFn(1)" :class="tabIndex==1?'ant-btn-primary':''">
                                    职级
                                </a-button>
                                <a-button v-show="role!=0" @click="tabFn(2)" :class="tabIndex==2?'ant-btn-primary':''">
                                    角色
                                </a-button>
                                <a-button v-show="positions!=0" @click="tabFn(3)"
                                          :class="tabIndex==3?'ant-btn-primary':''">职位
                                </a-button>
                            </a-button-group>
                            <!-- 选部门或人员或者 department==4选中心-->
                            <div v-show="tabIndex==0" class="ml-2 treeBox"
                                 :style="{height:department==2?'440px':'440px'}">
                                <!-- 00000部门or中心 -->
                                <a-tree v-if="department>3" checkable v-model="schoolModel" :treeData="schoolList"
                                        @select="this.onSelectSchool" @check="this.onCheckSchool"/>
                                <a-tree v-else :treeData="treeData" :defaultExpandedKeys="['9999']"
                                        @select="onSelect"></a-tree>
                            </div>

                            <!-- 选择职级 -->
                            <div v-show="tabIndex==1" class="ml-2 treeBox"
                                 :style="{height:department==2?'440px':'440px'}">
                                职级 暂无
                            </div>

                            <!-- 角色 -->
                            <div v-show="tabIndex==2" :style="{width:'650px',height:department==2?'440px':'440px'}">
                                <!-- <span style="display:inline-block;border-radius:4px; margin: 20px 0px 0px 20px;" :class="bool?'border ant-btn-primary':''">
                                    <span :class="bool?'button-rank ant-menu-item-selected':'button-rank'" style=" background:#F8F9FA;">主管/城市职能经理</span>
                                </span> -->
                                <span
                                        v-for="(item,i) in roleData"
                                        :key="i"
                                        @click="roleFn(role,i)"
                                        :class="item.bool?'border ant-btn-primary':''"
                                        style="display:inline-block;border-radius:4px; margin: 8px 0px 0px 12px;"
                                >
                                <span
                                        :class="item.bool?'button-rank ant-menu-item-selected':'button-rank'"
                                        style=" background:#F8F9FA;"
                                >{{item.title}}</span>
                            </span>
                            </div>
                            <!-- 选择职位 -->
                            <div v-show="tabIndex==3" class="ml-2 treeBox"
                                 :style="{height:department==2?'440px':'440px'}">
                                <a-tree :treeData="posts_type_arr" checkable v-model="positionsModel"
                                        @select="this.onSelectPosts" @check="this.onCheck"/>
                            </div>
                        </div>

                        <!-- 部门选项时出现的选项列表 -->
                        <div v-show="tabIndex==0" style="border-left:1px solid #e8e8e8;">
                            <div
                                    class="ant-menu-item-selected ml-2"
                                    style="font-size:16px;height:54px;line-height:60px;"
                            >选项列表
                            </div>
                            <div style="width:314px;overflow-y:auto;border-bottom:1px solid #e8e8e8;"
                                 :style="{height:department==2?'450px':'450px'}">
                                <div
                                        v-for="(list,i) in showPerson"
                                        :key="i"
                                        @click="selectPerson(list)"
                                        style="display:flex;align-items: center;padding: 5px 0px;border-bottom:1px solid #e8e8e8;cursor:pointer;"
                                >
                                    <a-avatar
                                            v-if="list.isuser==1"
                                            :size="42"
                                            class="ml-2 avaterW"
                                            :src="list.icon"
                                    />
                                    <div
                                            class="ml-2 avaterW ant-menu-item-selected border"
                                            v-else
                                    >{{list.title | substrName(0,1)}}
                                    </div>
                                    <div style="width:230px;">
                                        <div
                                                style="width:100%;white-space:nowrap;overflow:hidden;text-overflow: ellipsis;color:#333333;"
                                        >{{list.title}}
                                        </div>
                                        <span
                                                v-show="list.isuser==1 || department==2"
                                                style="font-size: 12px;color:#666666;"
                                        >{{list.position}}</span>
                                    </div>
                                </div>
                                <!-- <div style="display:flex;align-items: center;padding: 5px 0px;border-bottom:1px solid #e8e8e8;">
                                    <div style="width:220px;">
                                        <div style="color:#333333; width:100%;white-space:nowrap;overflow:hidden;text-overflow: ellipsis;">人员列表人员列表人员列表人表人员列表人员列表人员列表人员列表</div>
                                    </div>
                                    <a-icon type="close-circle" theme="filled" style="color:#e0e0e0;fontSize:18px" />
                                </div>-->
                            </div>
                        </div>
                    </div>

                    <!-- <div v-show="tabIndex!=1" style="width:610px;margin-top:10px;margin-left:20px;height:46px;line-height:46px;background:#F8F9FA;">
                          <div style="font-size:12px;"><span style="color:#666666;margin-left:16px;">当前项:</span><span class="ant-menu-item-selected"> 陈星 </span><a-icon v-show="tabIndex==0" type="right" /></div>
                    </div>-->
                </div>
                <!--已选列表  -->
                <div class="select-list">
                    <div class="ant-modal-title ml-2">已选列表</div>
                    <a-divider/>
                    <div style="height:526px;overflow-y:auto;">
                        <div
                                v-for="(item,i) in selectData"
                                :key="i"
                                style="display:flex;align-items: center;padding: 5px 0px;border-bottom:1px solid #e8e8e8; cursor:pointer;"
                        >
                            <a-avatar
                                    v-if="item.isuser==1"
                                    :size="42"
                                    class="ml-2"
                                    style="margin-right:10px;"
                                    :src="item.icon"
                            />
                            <div v-else class="ml-2 avaterW ant-menu-item-selected border">{{item.title |
                                substrName(0,1)}}
                            </div>
                            <div style="width:220px;">
                                <div style="color:#333333;width:100%;white-space:nowrap;overflow:hidden;text-overflow: ellipsis;">
                                    {{item.title}}
                                </div>
                                <span
                                        v-show="item.isuser==1"
                                        style="font-size: 12px;color:#666666;"
                                >{{item.position}}</span>
                            </div>
                            <a-icon
                                    @click="delSelect(i)"
                                    type="close-circle"
                                    theme="filled"
                                    style="color:#e0e0e0;fontSize:18px"
                            />
                        </div>
                        <!-- <a-row style="margin-bottom: 10px;" v-for="(item,index) in selectData">
                              <a-col :span="20">{{item.title}}</a-col>
                              <a-col :span="4" class="delete-icon"><a-icon type="delete" @click="_delete(index)"/></a-col>
                        </a-row>-->
                    </div>
                    <a-button type="primary" class="ml-2" style="margin-top:16px;width:120px;" @click="_confirm">确 定
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!-- loding -->
        <div
                v-show="spinning"
                style="position:fixed;left:50%;"
        >
            <a-spin style="margin: 200px 0%;">
                <a-icon
                        slot="indicator"
                        type="loading"
                        :spinning="spinning"
                        style="font-size: 44px;"
                        class="ant-menu-item-selected"
                        spin
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
    /*
    //  父级组件传参方式
    //     传入参数
    //  personObj:{
    //         role: 0, //1为单选 //2多选//0为不要选角色
    //         rank: 0,//职级 0为不选，1为选 //2多选
    //         positions: 0,//职位 0为不选，1选最顶层 //2选最底层
    //         nums: 1,//选的数量
    //         department: 0,//不选部门只选人，1为都选（人 部门都选），2为只选部门不选人，3不要部门与中心,4选中心最顶层 5选中心最底层
    //         contacts: 'recent',//常用联系人传recent 不要就传其他
            // school: 1,//中心
    //     },
    //   <person @getData="getData" v-bind="personObj"></person>//type值（中心部门1 职级2，角色3，职位4）部门与人为0
    */

    // var cookObj; //在常用联系人重复点击时用

    export default {
        name: "selectPersonnel",
        // props:{'rank': Number,'nums': Number,'department': Number},
        props: {//["rank", "nums", "department", "contacts", "selectArr",'role','positions'],
            role: {//0不选 1单选 2多选 角色
                type: Number,
                default: 0,
            },
            rank: {//职位 0为不选职位，其他都可以选职位
                type: Number,
                default: 0,
            },
            nums: {//数量 1只选1个
                type: Number,
                default: 1,
            },
            positions: {//职位
                type: Number,
                default: 0,
            },
            department: {//选部门 0为不选部门 1为都可以选 2为只选部门（不选人员） 3不选部门不选人（也就是不要部门这个节点）4中心
                // type: Number,
                default: 2,
            },
            contacts: {
                type: String,
                //contacts最近联系人recent (获取最近联系人时要处理过滤问题)
                default: 'no-recent'
            },
            selectArr: {
                // type: Object,
                default: [],
            },
            placeholder: {
                type: String,
            }
        },
        data() {//http://sapi.sikegroup.com/api/v1/coupon/getSchool
            return {
                // rank: '',//选职级
                // nums: '',//选人huan
                // department:'',//选部门
                // contacts: 'recent' //最近联系人
                //职级内容 isuser 0是部门 1是人 2是职级
                //cookdata,返回的数据
                spinning: false,
                roleData: [
                    {id: "123456", title: "PP1", bool: false, isuser: 2},
                    {id: "123452", title: "PP2", bool: false, isuser: 2},
                    {id: "123453", title: "PP3", bool: false, isuser: 2},
                    {id: "123457", title: "PP7", bool: false, isuser: 2},
                    {id: "123458", title: "PP8", bool: false, isuser: 2},
                    {id: "123459", title: "PP9", bool: false, isuser: 2},
                    {id: "123450", title: "主管/城市职能经理", bool: false}
                ],
                roleArr: [], //选中的角色
                tabIndex: 0,
                cookdata: [],
                treeData: [],
                dataList: [], //人LIst
                treeData: [], //树（左边）
                showPerson: [], //人员列表
                selectData: [], //选中的。。。
                schoolList: [],//中心数据
                // value: [],//选中的（人员或部门）
                // valueList: [],//选中的（人员或部门）集合
                visible: false,
                returnList: [],
                boxHeight: "32px",
                selectListBool: false, //下拉框显隐
                contactPersonList: [],
                arrPerson: [], //選中的常用联系人

                //岗位
                posts_type_arr: [
                    {
                        title: '1-1级', value: 123456, children: [
                            {title: '1-1.1级', value: 12345678},
                            {title: '1-1.2级', value: 12345679},
                            {title: '1-1.3级', value: 12345680}
                        ]
                    },
                    {
                        title: '2级', value: 1234567, children: [
                            {
                                title: '陈星2.1级', value: 1234568, children: [
                                    {title: '2.1-1.1级', value: 12345689},
                                    {title: '2.1-1.2级', value: 12345681},
                                    {title: '2.1-1.3级', value: 12345682},
                                ]
                            },
                            {title: '2-2.2级', value: 12345691},
                            {title: '2.3级', value: 12345693},
                        ]
                    }
                ], ///[],//岗位
                posts_type: [],//岗位选择

                // positions: this.positions,//职位 0为不选，其他为最顶层 //2选最底层
                schoolModel: [],
                positionsModel: [],
            };
        },
        watch: {
            // 监听回显数据
            selectArr: {
                immediate: true,
                // deep: true,
                handler(newValue) {
                    // console.log(this.selectArr,newValue)
                    this.cookdata = [];
                    this.returnList = [];
                    let school = [];
                    let positions = [];
                    if (newValue.length > 0) {
                        school = [];
                        positions = [];
                        for (let i = 0; i < newValue.length; i++) {
                            // console.log(newValue[i].label,'cxccxccx')
                            // this.value.push(this.selectArr[i].title);
                            if (newValue[i].type == 1) {
                                school.push(newValue[i].label)
                            } else if (newValue[i].type == 4) {
                                positions.push(newValue[i].label)
                            }
                            this.cookdata.push(newValue[i]);
                        }
                    } else {
                        this.selectData = [];
                    }

                    if (school.length > 0) {
                        this.schoolModel = school;
                    }
                    if (positions.length > 0) {
                        this.positionsModel = positions;
                    }
                    // this.cookdata.push({"title": '打开组织架构',"id": '-11111'})
                    this.selectData = newValue;
                    this.returnList = newValue;
                }
            }
        },
        created() {
            //window.console.log("prop", this.rank);
            //console.log(this.selectData);
            //  /skim/v1/user/positions'; //职位排

            // this.spinning = true;

            if (sessionStorage.getItem('slePerson')) {
                this.treeData = JSON.parse(window.sessionStorage.getItem('slePerson'));
            } else {
                this.$axios.get(8002, {Pid: 0}, res => {
                    // this.spinning = false;
                    if (res.data.list) {
                        this.treeData = res.data.list;
                        window.sessionStorage.setItem('slePerson', JSON.stringify(res.data.list));
                    } else {
                        this.$message.error("获取数据失败!");
                    }
                });
            }
            //中心(8008包含加盟)(9980不含加盟)
            if (sessionStorage.getItem('schoolList')) {
                this.schoolList = JSON.parse(window.sessionStorage.getItem('schoolList'));
            } else {
                this.$axios.get(8008, {}, res => {
                    // this.spinning = false;
                    if (res.data.data) {
                        this.schoolList = res.data.data;
                        window.sessionStorage.setItem('schoolList', JSON.stringify(res.data.data));
                    } else {
                        this.$message.error("获取数据失败!");
                    }
                });
            }
            // console.log(this.schoolList,'cx')
            //岗位
            if (!sessionStorage.getItem('posts_type_arr')) {
                this.$axios.get(8007, {}, res => {
                    if (res.data.code == 1) {
                        this.posts_type_arr = res.data.data;
                        window.sessionStorage.setItem('posts_type_arr', JSON.stringify(res.data.data));
                    } else {
                        this.$message.error("获取数据失败!");
                    }
                })
            } else {
                this.posts_type_arr = JSON.parse(window.sessionStorage.getItem('posts_type_arr'));
            }
            // if(sessionStorage.getItem('sleRole')){
            //     this.roleData = JSON.parse(window.sessionStorage.getItem('sleRole'));
            // }else{
            this.roleData = [];
            this.$axios.get(8027, {}, res => {
                // this.spinning = false;
                if (res.data.data) {
                    let arr = res.data.data;
                    arr.forEach((v, k) => {
                        v.type = 3;///type值（中心部门1 职级2，角色3，职位4）部门与人为0
                        this.roleData.push(v)
                    })
                    window.sessionStorage.setItem('sleRole', JSON.stringify(res.data.data));
                } else {
                    this.$message.error("获取数据失败!");
                }
            });
            // }
            // console.log(this.posts_type_arr)
            //  this.$axios.get(8003,{Pid:0},res=>{
            //   console.log(res.data.list)
            //   if (res.data.list) {
            //     // this.treeData = res.data.list;
            //   } else {
            //     this.$message.error("获取数据失败!");
            //   }
            // });//获取部门下的人
            //ajax获取已选后

            this.cookdata.push({title: "打开组织架构", id: "-11111"});
        },
        mounted() {
            this.boxHeight = this.$refs.selectBox.offsetHeight + 2 + "px";
            //console.log(this.boxHeight);
            this.personListAjax();
        },
        methods: {
            cx() {
            },
            onSearch(value) {
                //搜索框
                //window.console.log(value);
                //搜索的结果 该给this.showPerson
                let that = this;
                let Type = 'user';
                if(this.department ==2 || this.department ==4){
                        Type = 'ParentName';
                }
                that.$axios.post(8011, {Type: Type, Content: value}, res => {
                    that.showPerson = [];
                    let arr = res.data.data;
                    for (let i = 0; i < arr.length; i++) {
                        if(that.department ==2 || that.department ==4){
                            arr[i].typr = 1;//type值（中心部门1 职级2，角色3，职位4）部门与人为0
                        }else{
                            arr[i].typr = 0;//type值（中心部门1 职级2，角色3，职位4）部门与人为0
                        }

                        that.showPerson.push(arr[i]);
                    }

                })
                //ajax===>res ==> list
                //   for(let i=0;i<list.length;i++){//判断选部门还是人员
                //     if(this.department==0){
                //         if(list[i].isuser!=0){ this.showPerson.push(list[i]);}
                //     }else if(this.department==2){
                //         if(list[i].isuser==0){ this.showPerson.push(list[i]);}
                //     }else{ this.showPerson.push(list[i]);}
                // }
            },
            delChangeFn(id) {
                //已选中的删除
                this.selectData = [];
                for (let i = 0; i < this.returnList.length; i++) {
                    if (this.returnList[i].id != id) {
                        this.selectData.push(this.returnList[i]);
                    }
                }
                this.returnList = [];
                this.returnList = this.selectData;

                this.$emit("getData", this.returnList);
            },
            contactChangeFn(obj) {
                //常用联系人选中与取消
                this.selectData = this.returnList;
                let num = 0;
                if (this.returnList.length > 0) {
                    for (let i = 0; i < this.returnList.length; i++) {
                        if (this.returnList[i].id != obj.id) {
                            num++;
                        } else {
                            this.selectData.splice(i, 1);
                        }
                    }
                }
                //    if(cookObj == obj){
                //         cookObj = obj;
                //         this.selectData.splice(this.selectData.length-1,this.selectData.length)
                //     }else{
                //         cookObj = obj;
                //      }
                if (this.returnList.length == num) {
                    this.selectData.push(obj);
                }
                this.returnList = [];
                this.returnList = this.selectData;
                this.$emit("getData", this.returnList);
            },
            personListAjax(id) {
                let that = this;
                let xhr = new XMLHttpRequest();
                xhr.open("get", "https://smart.sikegroup.com/skim/v1/user/orgs", true);
                xhr.onreadystatechange = function (res) {
                    if (
                        xhr.readyState == 4 &&
                        xhr.status == 200 &&
                        xhr.responseText != ""
                    ) {
                        that.dataList = JSON.parse(res.target.response).users;
                    }
                };
                xhr.send();
            },
            //点击角色
            roleFn(type, num) {
                //window.console.log(type, num);
                let that = this;
                if (this.roleData[num].bool) {//如果已选中再点击就是不选中
                    this.roleData[num].bool = false;

                    for (let i = 0; i < this.roleArr.length; i++) {
                        if (this.roleData[num].id == this.roleArr[i].id) {
                            this.roleArr.splice(i, 1);
                        }
                    }
                    that.selectData.forEach((v, k) => {
                        if (v.id == this.roleData[num].id) {
                            that.selectData.splice(k, 1)
                        }
                    })
                } else {
                    if (this.roleData[num].bool == true) {
                        return false;
                    }
                    this.roleData[num].bool = true;
                    this.roleArr = [];
                    this.roleArr.push(this.roleData[num]);

                    try {
                        if (type == 1) {
                            //判断是否多选(1,为单选，其他为多选)
                            let delId = this.roleData[num].id; //找到不要删除的id；
                            //window.console.log("cx xxx", this.roleArr);
                            // this.roleArr[1].bool =false;
                            //window.console.log(delId);
                            for (let i = 0; i < this.selectData.length; i++) {
                                if (this.selectData[i].id != delId && this.selectData[i].bool) {
                                    //window.console.log("cx", this.selectData[i], i);
                                    this.selectData.splice(i, 1);
                                }
                            }
                            // this.roleArr.splice(1,1);
                            this.selectData.push(this.roleData[num]);
                            for (let i = 0; i < this.roleData.length; i++) {
                                if (this.roleData[num].id != this.roleData[i].id) {
                                    this.roleData[i].bool = false;
                                }
                            }
                        } else {
                            for (let i = 0; i < this.roleArr.length; i++) {
                                this.selectData.push(this.roleArr[i]);
                            }
                        }
                    } catch {
                    }
                }

            },
            _confirm() {
                let self = this;
                this.returnList = [];
                self.cookdata = [];
                // this.value=[];//外面（localstrang）选人清空 不然会重复
                // window.console.log(self.selectData);
                //选好人或部门等以后，把结果给到input里和下拉里面（默认选中）
                for (let i = 0; i < self.selectData.length; i++) {
                    // self.value.push(self.selectData[i].title);
                    self.cookdata.push(self.selectData[i]);
                }
                self.returnList = self.returnList.concat(self.selectData);
                self.visible = false;
                self.selectData = [];
                // self.cookdata.push({"title": '打开组织架构',"id": '-11111'});
                this.$emit("getData", this.returnList);
                // this.returnList=[];
            },
            // _delete(i){
            //     let self = this;
            //     this.$confirm({
            //         title: '确认框',
            //         content: '您确定要删除吗？',
            //         okText: '确认',
            //         cancelText: '取消',
            //         onOk:function () {
            //             self.selectData.splice(i,1);
            //             self.$message.success('删除成功');
            //         }
            //     });
            // },

            //点击树
            onSelect(selectedKeys, info) {
                window.console.log(selectedKeys, info, info.value)
                let id = info.selectedNodes[0].data.props.value;
                this.showPerson = [];
                //当前点击的部门
                let obj = {};
                obj.icon = "https://smart-resource.sikegroup.com/user-select-org-1.png";
                obj.title = info.selectedNodes[0].data.props.title;
                obj.id = id;
                obj.isuser = 0;
                obj.WxId = info.selectedNodes[0].data.props.WxId ? info.selectedNodes[0].data.props.WxId : '';
                if (this.department != 0) {
                    this.showPerson.push(obj);
                }
                let list = this.dataList[id].map(o => {
                    return {
                        title: o.name,
                        id: o.id,
                        icon: o.icon,
                        position: o.position,
                        isuser: o.isuser,
                        WxId: o.WxId ? o.WxId : ''
                    };
                });
                //console.log(list);
                try {
                    for (let i = 0; i < list.length; i++) {
                        if (this.department == 0) {
                            if (list[i].isuser != 0) {
                                this.showPerson.push(list[i]);
                            }
                        } else if (this.department == 2) {
                            if (list[i].isuser == 0) {
                                this.showPerson.push(list[i]);
                            }
                        } else {
                            this.showPerson.push(list[i]);
                        }
                    }
                } catch {
                }
                //window.console.log("cx", this.showPerson);
            },
            //人员列表选人
            selectPerson(obj) {
                // console.log(obj)
                try {
                    if (this.selectData.length > 0) {
                        for (let i = 0; i < this.selectData.length; i++) {
                            if (this.selectData[i].id == obj.id) return false;
                        }
                    } else {
                        this.selectData = [];
                    }
                } catch {
                }
                obj.type = 0;//type值（中心部门1 职级2，角色3，职位4）部门与人为0
                this.selectData.push(obj);
                //  if(this.nums==1){
                this.selectData.splice(this.nums - 1, this.selectData.length - this.nums);
                //  }
                //window.console.log(this.selectData);
            },
            //删除已选人员
            delSelect(i) {
                window.console.log(i, this.selectData, this.selectData[i], this.positions);
                let delId = this.selectData[i].id;
                try {
                    if (this.selectData[i].bool) {
                        this.selectData[i].bool = false;
                    }
                } catch {
                }
                if ((this.selectData[i].type == 1) && (this.department == 5)) {
                    let repeat = [];
                    for (let j = 0; j < this.selectData.length; j++) {
                        if ((this.selectData[j].type == 1) && (this.selectData[i].label != this.selectData[j].label)) {
                            repeat.push(this.selectData[j].label)
                        }
                    }
                    this.schoolModel = repeat;
                    // this.selectData.splice(i,1);
                } else if ((this.selectData[i].type == 4) && (this.positions == 2)) {
                    let repeat = [];
                    for (let j = 0; j < this.selectData.length; j++) {
                        if ((this.selectData[j].type == 4) && (this.selectData[i].label != this.selectData[j].label)) {
                            repeat.push(this.selectData[j].label)
                        }
                    }
                    this.positionsModel = repeat;
                    // this.selectData.splice(i,1);
                }
                // console.log(this.selectData)
                this.selectData.splice(i, 1);

            },
            // _handleChange(value) {
            //     window.console.log(value)
            //     console.log(this.returnList)
            //             // for(let i=0;i<value.length;i++){
            //             //    if(value[i]!=-11111){this.selectData.push(value[i]);}
            //             // }
            //             // console.log(this.selectData)

            //     let list = [];
            //     // let val = [];
            //     let valu = [];//id找对象信息
            //     for(let i=0;i<value.length;i++){
            //         for(let j=0;j<this.cookdata.length;j++){
            //             if(value[i]==this.cookdata[j].id){valu.push(this.cookdata[j]);}
            //         }
            //     }
            //     for (let i = 0; i < valu.length; i++) {
            //         if (this.department == 0) {
            //             if (valu[i].isuser == 1) {
            //                 list.push(valu[i]);
            //                 // val.push(valu[i].id)
            //             }
            //         } else if (this.department == 2) {
            //             if (valu[i].isuser == 0) {
            //                 list.push(valu[i]);
            //                 // val.push(valu[i].id)
            //             }
            //         } else {
            //             list.push(valu[i]);
            //             // val.push(valu[i].id)
            //         }
            //     }
            //     if(val.length>this.nums){
            //         window.console.log(val,list,this.nums)
            //     //    val=val.slice((val.length-this.nums),(val.length));
            //         list=list.splice((list.length-this.nums),(list.length));
            //     }
            //     this.returnList = list;
            //     window.console.log(list)
            //     this.$emit('getData',this.returnList)
            //     console.log(this.returnList)
            //     // this.value=[]
            // },
            _showModal() {
                this.visible = !this.visible;
                this.selectData = [];
                // console.log(this.value)
                if (this.visible) {
                    this.selectData = this.returnList;
                }
            },
            tabFn(i) {
                this.tabIndex = i;
            },

            //岗位选择
            onSelectPosts(selectedKeys, info) {
                let id = info.selectedNodes[0].data.props.value;
                //  console.log('selected', selectedKeys, id);
            },
            onCheck(checkedKeys, info) {
                let that = this;
                let arr_info = []; //存选择的数据
                // console.log(checkedKeys);
                // console.log(info);
                for (let i = 0; i < checkedKeys.length; i++) {
                    let obj = {};//将数据放在一起（包括label（keys） 如'0-1-1'） 到时候根据keys去拿要的数据
                    obj.label = checkedKeys[i];
                    obj.id = info.checkedNodes[i].data.props.value;
                    obj.type = 4;//判断是哪部分的数据 （中心部门1 职级2，角色3，职位4）部门与人为0
                    obj.title = info.checkedNodes[i].data.props.title;
                    obj.PositionLabel = info.checkedNodes[i].data.props.PositionLabel;
                    arr_info.push(obj);
                }
                ;
                if (this.positions == 1) {
                    //最顶层department=4
                    for (let i = 0; i < checkedKeys.length; i++) {
                        for (let j = 0; j < checkedKeys.length; j++) {
                            if (checkedKeys[j].indexOf(checkedKeys[i]) == 0) {
                                if (checkedKeys[i] != checkedKeys[j]) {
                                    //  checkedKeys.splice(j,1);
                                    // changeArr[j]=undefined;
                                    arr_info[j] = undefined;
                                }
                            }
                        }
                    }
                } else {
                    //最底层positions=2
                    // for(let i=0;i<checkedKeys.length;i++){ //只用于3层结构
                    //     let num =0;
                    //     for(let j=0;j<checkedKeys.length;j++){
                    //         if(checkedKeys[i].indexOf(checkedKeys[j])==0){
                    //             num++;
                    //         }
                    //     }
                    //     if(num<3){
                    //         arr_info[i]= undefined;
                    //     }
                    //  }
                    //看不懂就别动（作用 去重）
                    for (let i = 0; i < checkedKeys.length; i++) {
                        let j = ((i + 1) > (checkedKeys.length - 1)) ? 0 : i + 1;

                        if ((checkedKeys[i].indexOf(checkedKeys[j]) == -1) && (checkedKeys[j].indexOf(checkedKeys[i]) == 0)) {
                            arr_info[i] = undefined;
                        }
                    }
                }

                let back_posts_arr = [];//最后返回页面的数据
                let selectArr = [];
                let delArrIndex = [];
                if (that.selectData.length > 0) {
                    for (let i = 0; i < that.selectData.length; i++) {
                        if (!that.selectData[i].label) {
                            selectArr.push(that.selectData[i]);
                        }
                        if (that.selectData[i].type == 4) {
                            // that.selectData.splice(i,i+1);
                            delArrIndex.push(i)
                        }
                    }
                }
                for (let j = 0; j < delArrIndex.length; j++) {
                    that.selectData.splice(delArrIndex[j] - j, 1);
                }
                // console.log(that.selectData);
                // that.selectData =[];
                for (let i = 0; i < arr_info.length; i++) {//过滤undefined
                    if (arr_info[i]) {
                        back_posts_arr.push(arr_info[i]);
                        that.positionsModel.push(arr_info[i].label)
                        let sun = 0;
                        for (let j = 0; j < selectArr.length; j++) {
                            if (arr_info[i].id == selectArr[j].id) {
                                sun++;
                            }
                        }
                        if (sun == 0) {
                            selectArr.unshift(arr_info[i])
                        }
                    }
                }
                //  console.log( back_posts_arr)
                for (let i = 0; i < selectArr.length; i++) {
                    that.selectData.push(selectArr[i]);
                }
                // console.log("end",back_posts_arr,that.selectData)
            },
            //中心选择
            onSelectSchool(selectedKeys, info) {
                let id = info.selectedNodes[0].data.props.value;
                // console.log('selected', selectedKeys, id);
            },
            onCheckSchool(checkedKeys, info) {
                // console.log(checkedKeys, info);
                let that = this;
                let arr_info = []; //存选择的数据
                // console.log(checkedKeys);
                // console.log(info);
                for (let i = 0; i < checkedKeys.length; i++) {
                    let obj = {};//将数据放在一起（包括label（keys） 如'0-1-1'） 到时候根据keys去拿要的数据
                    obj.label = checkedKeys[i];
                    obj.id = info.checkedNodes[i].data.props.value;
                    obj.type = 1;//判断是哪部分的数据 （中心部门1 职级2，角色3，职位4）部门与人为0
                    obj.title = info.checkedNodes[i].data.props.title;
                    arr_info.push(obj);
                }
                ;

                if (this.department == 4) {
                    // console.log(checkedKeys);
                    // console.log(arr_info);
                    //最顶层department=4
                    for (let i = 0; i < checkedKeys.length; i++) {
                        for (let j = 0; j < checkedKeys.length; j++) {
                            let checkedKeysStr = '';
                            checkedKeysStr = checkedKeys[j] + '';
                            if (checkedKeysStr.indexOf(checkedKeys[i]) == 0) {
                                if (checkedKeys[i] != checkedKeys[j]) {
                                    arr_info[j] = undefined;
                                }
                            }
                        }
                    }
                } else {
                    //最底层department=5
                    // for(let i=0;i<checkedKeys.length;i++){ //只用于3层结构
                    //     let num =0;
                    //     for(let j=0;j<checkedKeys.length;j++){
                    //         if(checkedKeys[i].indexOf(checkedKeys[j])==0){
                    //             num++;
                    //         }
                    //     }
                    //     if(num<3){
                    //         arr_info[i]= undefined;
                    //     }
                    //  }
                    //看不懂就别动（作用 去重）
                    for (let i = 0; i < checkedKeys.length; i++) {
                        let j = ((i + 1) > (checkedKeys.length - 1)) ? 0 : i + 1;
                        if ((checkedKeys[i].indexOf(checkedKeys[j]) == -1) && (checkedKeys[j].indexOf(checkedKeys[i]) == 0)) {
                            arr_info[i] = undefined;
                        }
                    }
                }
                let back_posts_arr = [];//最后返回页面的数据
                let selectArr = [];
                let delArrIndex = [];
                if (that.selectData.length > 0) {
                    for (let i = 0; i < that.selectData.length; i++) {
                        if (!that.selectData[i].label) {
                            selectArr.push(that.selectData[i]);
                        }
                        if (that.selectData[i].type == 1) {
                            // that.selectData.splice(i,i+1);
                            delArrIndex.push(i)
                        }
                    }
                }
                for (let j = 0; j < delArrIndex.length; j++) {
                    that.selectData.splice(delArrIndex[j] - j, 1);
                }
                // console.log(that.selectData);
                // that.selectData =[];
                for (let i = 0; i < arr_info.length; i++) {//过滤undefined
                    if (arr_info[i]) {
                        back_posts_arr.push(arr_info[i]);
                        that.schoolModel.push(arr_info[i].label);
                        let sun = 0;
                        for (let j = 0; j < selectArr.length; j++) {
                            if (arr_info[i].id == selectArr[j].id) {
                                sun++;
                            }
                        }
                        if (sun == 0) {
                            selectArr.unshift(arr_info[i])
                        }
                    }
                }
                //  console.log( back_posts_arr)
                for (let i = 0; i < selectArr.length; i++) {
                    that.selectData.push(selectArr[i]);
                }
                // console.log("end",back_posts_arr,that.selectData)
            },

        }
    };
</script>

<style scoped lang="less">
    .selectPersonnel {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        //選中的樣式
        .selectGather {
            width: 100%;
            height: 100%;
            min-height: 32px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 0px 4px 0 4px;
            background-color: #ffffff;
            font-size: 14px;
            position: relative;
            text-align: left;
            overflow: hidden;
            .selectItem {
                display: inline-block;
                height: 24px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.65);
                background-color: #fafafa;
                border: 1px solid #e8e8e8;
                border-radius: 2px;
                margin: 3px 4px 0px 0px;
                padding-left: 8px;
            }
            .contacts {
                position: absolute;
                left: 0px;
                z-index: 100;
                width: 100%;
                border-radius: 4px;
                padding: 10px;
                background-color: #ffffff;
                box-shadow: 2px 4px 6px rgba(183, 176, 183, 0.5);
                .contacts_list {
                    // max-height: 200px;
                    // height: 200px;
                    overflow-y: auto;
                    .contact_item {
                        height: 30px;
                        line-height: 30px;
                        white-space: nowrap;
                        padding: 0px 10px;
                        overflow: hidden;
                        &:hover {
                            background-color: #f7f8f9;
                        }
                        input[type="checkbox"] {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            text-align: center;
                            vertical-align: middle;
                            line-height: 18px;
                            opacity: 0;
                            position: relative;
                        }
                        //   input[type="checkbox"]::before {
                        //     content: "";
                        //     position: absolute;
                        //     top: 0;
                        //     left: 0;
                        //     background: #fff;
                        //     width: 100%;
                        //     height: 100%;
                        //     border: 1px solid #d9d9d9;
                        //   }
                        //   input[type="checkbox"]:checked::before {
                        //     content: "\2713";
                        //     background-color: #fff;
                        //     position: absolute;
                        //     top: 0;
                        //     left: 0;
                        //     width: 100%;
                        //     border: 1px solid #e50232;
                        //     color: #e50232;
                        //     font-size: 20px;
                        //     font-weight: bold;
                        //   }
                    }
                }
            }
        }
    }

    .fontStyle {
        font-weight: 600;
        color: #333333;
    }

    .padd {
        padding: 0px 0px;
    }

    .tree-skip-btn {
        width: 130%;
        text-align: center;
        // color:#40A9FF;
        position: absolute;
        top: -8px;
        bottom: 0px;
        left: 50%;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        transform: translateX(-50%);
        background: #fff;
        z-index: 10000;
        font-size: 14px;
        height: 36px;
        line-height: 42px;
    }

    .tree-btn {
        width: 32px;
        margin-left: 5px;
        font-size: 16px;
        position: relative;
        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    //    model
    .personnel-list {
        width: 400px;
        height: 500px;
        flex: 2;
        // color: #333333;
        padding: 5px 0px;
        .ant-input-search {
            margin: 14px 16px;
        }
        .ant-btn-group {
            .ant-btn {
                width: 74px;
                padding: 0px;
            }
        }
        .list {
            height: 30px;
            line-height: 30px;
            color: #666666;
        }
    }

    //职级的按钮选项
    .button-rank {
        display: inline-block;
        padding: 6px 16px;
        font-size: 14px;
        border: 1px solid #fff;
        border-radius: 4px;
    }

    .select-list {
        height: 650px;
        flex: 1;
        padding: 5px 0px;
        background: #f8f9fa;
    }

    .ant-modal-title {
        height: 50px;
        font-size: 18px;
        line-height: 50px;
    }

    .ml-2 {
        margin-left: 20px;
    }

    .ant-divider-horizontal {
        margin: 0px 0px;
    }

    .delete-icon {
        text-align: center;
        cursor: pointer;
    }

    .delete-icon i {
        color: #f5222d;
    }

    .avaterW {
        width: 42px;
        height: 42px;
        background: #ffffff;
        font-size: 18px;
        font-weight: 600;
        margin-right: 10px;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
    }

    .treeBox {
        font-size: 14px;
        margin-top: -8px;
        width: 320px;
        overflow: auto;
    }
</style>
